<template>
  <b-card>
    <validation-observer ref="accountRules" tag="form">
      <b-form class="mt-1" @submit.prevent>
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">
              Peminjaman
            </h5>
          </b-col>
          <b-col md="6">
            <b-form-group label="ID Transaksi" label-for="birth_place">
              <validation-provider #default="{ errors }" name="Kelas" rules="required">
                <b-form-input id="birth_place" v-model="dataParent.birth_place"
                  :state="errors.length > 0 ? false : null" placeholder="ID Transaksi" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="ID Anggota" label-for="birth_place">
              <validation-provider #default="{ errors }" name="Kelas" rules="required">
                <b-form-input id="birth_place" v-model="dataParent.birth_place"
                  :state="errors.length > 0 ? false : null" placeholder="ID Anggota" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Peminjaman" label-for="birth_place">
              <validation-provider #default="{ errors }" name="Email" rules="required">
                <b-form-input id="birth_place" v-model="dataParent.birth_place"
                  :state="errors.length > 0 ? false : null" placeholder="Peminjaman" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Kelas" label-for="birth_place">
              <validation-provider #default="{ errors }" name="Tanggal Terbit" rules="required">
                <b-form-input id="birth_place" v-model="dataParent.birth_place"
                  :state="errors.length > 0 ? false : null" placeholder="Kelas" type="text" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Tgl Pinjam" label-for="birth_place">
              <validation-provider #default="{ errors }" name="Tgl Pinjam" rules="required">
                <b-form-input id="birth_place" v-model="dataParent.birth_place"
                  :state="errors.length > 0 ? false : null" placeholder="Tgl Pinjam" type="date" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Tgl Kembali" label-for="birth_place">
              <validation-provider #default="{ errors }" name="Penerbit" rules="required">
                <b-form-input id="birth_place" v-model="dataParent.birth_place"
                  :state="errors.length > 0 ? false : null" placeholder="Tgl Kembali" type="date" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Admin Perpus" label-for="birth_place">
              <validation-provider #default="{ errors }" name="Admin Perpus" rules="required">
                <b-form-input id="birth_place" v-model="dataParent.birth_place"
                  :state="errors.length > 0 ? false : null" placeholder="Admin Perpus" type="text" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>  
            <b-form-group label="Admin Penerima" label-for="birth_place">
              <validation-provider #default="{ errors }" name="Admin Penerima" rules="required">
                <b-form-input id="birth_place" v-model="dataParent.birth_place"
                  :state="errors.length > 0 ? false : null" placeholder="Admin Penerima" type="text" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>          
          </b-col>

        </b-row>
        <b-row class="text-right">
          <b-col>
            <!-- submit and reset -->
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
              @click="formSubmitted">
              Submit
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
              @click="() => $router.go(-1)">
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm, BButton,
} from 'bootstrap-vue'
import { required, email, size } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'
// import { codeIcon } from './code'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BForm,
    BButton,
  },
  data() {
    return {
      required,
      email,
      size,
      dataCountries,
      dataProvinces,
      dataCities,
      dataDistrics,
      dataSubDistrics,
      dataParent: {
        student_nisn: '',
        relationship: '',
        education_level_id: '',
        name: '',
        birth_place: '',
        birth_date: '',
        religion: '',
        nationality: '',
        education_major: '',
        profession: '',
        salary: '',
        province: '',
        city: '',
        district: '',
        subdistrict: '',
        rw: '',
        rt: '',
        address: '',
        parental_status: '',
        handphone: '',
        email: '',
        nik: '',
      },
      locations: {
        province: '',
        city: '',
        distric: '',
        subdistric: '',
      },
      // selectedContry: '',
      // selectedLanguage: '',
      // name: '',
      // emailValue: '',
      // PasswordValue: '',
      // passwordCon: '',
      // first_name: '',
      // last_name: '',
      // address: '',
      // landMark: '',
      // pincode: '',
      // twitterUrl: '',
      // facebookUrl: '',
      // googleUrl: '',
      // linkedinUrl: '',
      // city: '',
      // required,
      // email,
      // codeIcon,
      dataGender: [
        { value: 'M', text: 'Laki-laki' },
        { value: 'F', text: 'Perempuan' },
      ],
      dataReligion: [
        { value: 1, text: 'Islam' },
        { value: 2, text: 'Protestan' },
        { value: 3, text: 'Katolik' },
        { value: 4, text: 'Hindu' },
        { value: 5, text: 'Buddha' },
        { value: 6, text: 'Khonghucu' },
      ],
      dataReadingQuran: [
        { value: 'lancar', text: 'Lancar' },
        { value: 'belum_lancar', text: 'Belum Lancar' },
        { value: 'belum_bisa', text: 'Belum Bisa' },
      ],
      dataEducation: [{ value: 1, text: 'SD/MI' }],
      dataParentStatus: [
        { value: '1', text: 'Ayah' },
        { value: '2', text: 'Ibu' },
        { value: '3', text: 'Wali' },
      ],
      dataParentEducation: [
        { value: '1', text: 'SD' },
        { value: '2', text: 'SMP' },
        { value: '3', text: 'SMA' },
        { value: '4', text: 'S1' },
        { value: '5', text: 'S2' },
        { value: '6', text: 'S3' },
      ],
      dataMutations: [
        { value: 'Y', text: 'Ya' },
        { value: 'N', text: 'Tidak' },
      ],
      dataClasss: [{ value: '4nvoeygeoxw3zpra', text: 'VII' }],
      listSiswa: [],
    }
  },
  computed: {
    filterCities() {
      return this.dataCities.filter(
        item => item.parent === this.locations.province.wilayah_id,
      )
    },
    filterDistrics() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.city.wilayah_id,
      )
    },
    filterSubDistrics() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.distric.wilayah_id,
      )
    },
    filterCitiesFather() {
      return this.dataCities.filter(
        item => item.parent === this.locations.father_province.wilayah_id,
      )
    },
    filterCitiesMother() {
      return this.dataCities.filter(
        item => item.parent === this.locations.mother_province.wilayah_id,
      )
    },
    filterDistricsFather() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.father_city.wilayah_id,
      )
    },
    filterDistricsMother() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.mother_city.wilayah_id,
      )
    },
    filterSubDistricsFather() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.father_distric.wilayah_id,
      )
    },
    filterSubDistricsMother() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.mother_distric.wilayah_id,
      )
    },
  },
  created() {
    this.getSiswa()
  },
  methods: {
    async getSiswa() {
      try {
        const response = await this.$http.get('/students')
        this.listSiswa = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async formSubmitted() {
      try {
        const dataFormParent = new FormData()
        Object.keys(this.dataParent).forEach(key => {
          dataFormParent.append(key, this.dataParent[key])
        })
        dataFormParent.append('province', this.locations.province.name)
        dataFormParent.append('city', this.locations.city.name)
        dataFormParent.append('district', this.locations.distric.name)
        dataFormParent.append('subdistrict', this.locations.subdistric.name)
        await this.$http.post('/studentfamilies', dataFormParent)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Orangtua Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'data-orangtua' })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Orangtua Tidak Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        console.log(err)
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
